export default () => {
  const mobileButtons = document.querySelectorAll(".header__menu-mobile");
  const mobileMenu = document.querySelector(".menu-mobile");
  const closeButton = document.querySelector(".menu-mobile__button-container");

  for (let mobileButton of mobileButtons) {
    mobileButton.addEventListener("click", e => {
      e.preventDefault();
      mobileMenu.classList.add("menu-mobile--opened");
      document.body.style.overflow = "hidden";
    });
  }

  closeButton.addEventListener("click", e => {
    e.preventDefault();
    mobileMenu.classList.remove("menu-mobile--opened");
    document.body.style = null;
  });
};
